import type { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReferralsApiService } from '@dev-fast/backend-services';
import type {
  Affise,
  ICampaignInfo,
  IError,
  IPromoCode,
  IPromocodeData,
  IReferralCampaign,
  IReferralCampaignDto,
  IReferralCampaignLite,
  IReferralCampaignReport,
} from '@dev-fast/types';
import { ModalNames, PromoStatus } from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import { iif, patch, updateItem } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import type { Observable } from 'rxjs';
import { catchError, finalize, of, retry, switchMap, tap, throwError, timer } from 'rxjs';

import { Logout } from '@app/auth';
import { AnalyticsService } from '@app/core/analytics-service';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { ModalsState, OpenModal } from '@app/core/state/modals';
import { InitSuccess, UserState } from '@app/core/state/user-store';
import type { RouterStateParams } from '@app/core/state/utils';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import {
  ActivatePromoCode,
  ActivateRefCode,
  AffiseEvent,
  ChangeCampaign,
  ClickPromoCode,
  ClickRefCode,
  CreateCampaign,
  GetCampaignReferralsReport,
  GetCampaigns,
  GetCurrentPromo,
  GetInfo,
  OpenPromoModal,
  SetActiveCampaign,
} from './referrals.actions';
import type { ReferralsStateModel } from './referrals-state.model';
import { REFERRALS_INITIAL_STATE } from './referrals-state.model';

@State<ReferralsStateModel>({
  name: 'referrals',
  defaults: REFERRALS_INITIAL_STATE,
})
@Injectable()
export class ReferralsState {
  readonly #frameMessageService = inject(IFrameMessageService, { optional: true });
  readonly #isServer = inject(IS_SERVER_TOKEN);
  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #storage = inject(LocalStorageService);
  readonly #apiService = inject(ReferralsApiService);
  readonly #analyticsService = inject(AnalyticsService);
  readonly #notificationsService = inject(NotificationsService);

  constructor() {
    this.#apiService
      .bonusCaseReceived()
      .pipe(
        tap((value) => {
          if (!this.#store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.CASES_BONUS)) {
            this.#store.dispatch(new OpenModal(ModalNames.CASES_BONUS, value.payload));
          }
        }),
      )
      .subscribe();
  }

  @Selector()
  static promoStatus({ activationErr, promoActivated, activePromoCode }: ReferralsStateModel): PromoStatus {
    if (activationErr && !promoActivated) {
      return PromoStatus.ERROR;
    }
    if (activePromoCode && promoActivated) {
      return PromoStatus.SUCCESS;
    }
    return PromoStatus.DEFAULT;
  }

  @Selector()
  static activePromoCode({ activePromoCode }: ReferralsStateModel): IPromoCode | null {
    return activePromoCode;
  }

  @Selector()
  static activationErr({ activationErr }: ReferralsStateModel): IError | null {
    return activationErr;
  }

  @Selector()
  static promoData({ activationErr, activePromoCode, promoActivated }: ReferralsStateModel): IPromocodeData {
    return { activationErr, activePromoCode, promoActivated };
  }

  // --------------------------------------------------------
  @Selector()
  static campaignReferralsReport({ campaignReferralsReport }: ReferralsStateModel): IReferralCampaignReport[] {
    return campaignReferralsReport;
  }

  @Selector()
  static campaigns({ campaigns }: ReferralsStateModel): IReferralCampaign[] {
    return campaigns;
  }

  @Selector()
  static campaign({ campaign }: ReferralsStateModel): IReferralCampaign | null {
    return campaign;
  }

  @Selector()
  static info({ info }: ReferralsStateModel): ICampaignInfo | null {
    return info;
  }

  @Selector()
  static isReferralLocked({ isReferralLocked }: ReferralsStateModel): boolean {
    return isReferralLocked;
  }

  @Action(GetCampaignReferralsReport)
  getCampaignReferralsReport(
    { patchState }: StateContext<ReferralsStateModel>,
    { params }: GetCampaignReferralsReport,
  ): Observable<IReferralCampaignReport[]> {
    return this.#apiService.getCampaignReferralsReport(params).pipe(
      tap((response) =>
        patchState({
          campaignReferralsReport: response,
        }),
      ),
    );
  }

  @Action(GetCampaigns)
  getCampaigns({ patchState }: StateContext<ReferralsStateModel>): Observable<IReferralCampaignDto> {
    return this.#apiService.getReferralCampaigns().pipe(
      tap((response) => {
        // TODO убрать, бэк поменялся в дэве. Новый вариант response.campaigns раньше все лежало в response
        const campaigns = response.campaigns || (response as any);
        const isReferralLocked = response.isReferralLocked || false;
        patchState({ campaigns: campaigns ?? [], isReferralLocked });
      }),
      catchError((error: HttpErrorResponse) => {
        patchState({
          campaign: null,
        });
        return this.#onError(error);
      }),
    );
  }

  @Action(GetInfo, { cancelUncompleted: true })
  getInfo({ patchState }: StateContext<ReferralsStateModel>): Observable<any> {
    return this.#apiService.getInfo().pipe(tap((response) => patchState({ info: response })));
  }

  @Action(SetActiveCampaign)
  setActiveCampaign({ patchState, getState }: StateContext<ReferralsStateModel>, { id }: SetActiveCampaign): void {
    const { campaigns } = getState();
    const campaign = campaigns?.find((item) => item.id === id);
    patchState({ campaign: campaign ? campaign : null });
  }

  @Action(CreateCampaign)
  createCampaign({ dispatch }: StateContext<ReferralsStateModel>, { payload }: CreateCampaign): Observable<void> {
    return this.#apiService.requestCampaignCreate(payload).pipe(
      catchError((error: HttpErrorResponse) => this.#onError(error)),
      switchMap(() => dispatch(new GetCampaigns())),
    );
  }

  @Action(ChangeCampaign)
  changeCampaign(
    { setState, getState }: StateContext<ReferralsStateModel>,
    { payload }: ChangeCampaign,
  ): Observable<IReferralCampaignLite> {
    return this.#apiService.requestCampaignPatch(payload).pipe(
      tap((response) => {
        const { campaign } = getState();
        setState(
          patch<ReferralsStateModel>({
            campaigns: updateItem<IReferralCampaign>((o) => o?.id === payload.id, patch<IReferralCampaign>({ ...response })),
            campaign: iif((el) => !!el && el.id === response.id, campaign && { ...campaign, ...response }, campaign),
          }),
        );
      }),
    );
  }

  // ----------------------------------- ------------------------------------------
  @Action(RouterNavigation<RouterStateParams>)
  routerNavigation(
    { dispatch, patchState }: StateContext<ReferralsStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>,
  ): void {
    const { queryParams } = routerState;
    if (!queryParams || this.#isServer) {
      return;
    }
    if (queryParams['clickid'] || queryParams['utm_campaign']) {
      const affise: Affise = {};
      if (queryParams['clickid']) {
        affise['clickId'] = queryParams['clickid'];
      }
      if (queryParams['utm_campaign']) {
        affise['utmCampaign'] = queryParams['utm_campaign'];
      }

      patchState({ affise });
      this.#storage.set('affise', affise);
      dispatch(new AffiseEvent(affise));
    }
    // NOTE: Костыль для того, чтобы старый формат рефералок с # перебрасывал на новый формат
    if (routerState.url.includes('#r')) {
      this.#redirectFromLegacyPromo(routerState.url);
    }
    if (queryParams['ref'] && !this.#getRefCodeFromStorage()) {
      dispatch(new ClickRefCode(queryParams['ref']));
    }

    if (queryParams['promo'] && !this.#getPromoCodeFromStorage()) {
      dispatch(new ClickPromoCode(queryParams['promo']));
    }
  }

  @Action(GetCurrentPromo)
  getCurrentPromo({ patchState }: StateContext<ReferralsStateModel>): Observable<IPromoCode | null> {
    return this.#apiService.getCurrentReferralCampaign().pipe(
      tap((response) => {
        patchState({
          activePromoCode: response,
          activationErr: null,
          promoActivated: false,
        });
      }),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          activePromoCode: null,
          activationErr: error,
        });
        return of(error);
      }),
    );
  }

  @Action(AffiseEvent)
  affiseEvent({ patchState }: StateContext<ReferralsStateModel>, { affise }: AffiseEvent): Observable<void> | undefined {
    const user = this.#store.selectSnapshot(UserState.user);
    if (!user) {
      return;
    }
    return this.#analyticsService.affiseEvent(affise).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap(() => {
        this.#storage.remove('affise');
        patchState({ affise: null });
      }),
    );
  }

  @Action(ActivatePromoCode)
  activatePromoCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ActivatePromoCode): Observable<any> {
    return this.#apiService.requestPromoActivation(code).pipe(
      tap(({ appId, ...response }) => {
        patchState({
          activePromoCode: response,
          activationErr: null,
          promoActivated: true,
        });
        if (this.#frameMessageService) {
          this.#frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'promoActivated',
            payload: response,
          });
        }
      }),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          activationErr: error,
          promoActivated: false,
        });
        this.#notificationsService.addErrorNotification(error.message, { showToast: false });
        return of(error);
      }),
      finalize(() => this.#removeCodesFromStorage()),
    );
  }

  @Action(ActivateRefCode, { cancelUncompleted: true })
  activateRefCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ActivateRefCode): Observable<any> {
    return this.#apiService.requestPromoActivation(code).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap(({ appId, ...response }) => {
        patchState({
          activePromoCode: response,
          activationErr: null,
          promoActivated: true,
        });
        if (this.#frameMessageService) {
          this.#frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'promoActivated',
            payload: response,
          });
        }
      }),
      switchMap((val) => dispatch(new OpenPromoModal(val))),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          activationErr: error,
          promoActivated: false,
        });
        this.#notificationsService.addErrorNotification(error.message, { showToast: false });
        return of(error);
      }),
      finalize(() => this.#removeCodesFromStorage()),
    );
  }

  @Action(ClickRefCode)
  clickRefCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ClickRefCode): Observable<any> {
    return this.#apiService.getRefCodeInfo(code).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap((response) => {
        this.#storage.set('referralCode', response);
      }),
      switchMap((res) => {
        return this.#store.selectSnapshot(UserState.user) ? dispatch(new ActivateRefCode(res.code)) : dispatch(new OpenPromoModal(res));
      }),
      catchError(({ error, status }: HttpErrorResponse) => {
        if (status !== 404) {
          this.#storage.set('unknownRefCode', code);
        }
        this.#notificationsService.addErrorNotification(error.message);
        return of(error);
      }),
    );
  }

  @Action(ClickPromoCode)
  clickPromoCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ClickPromoCode): Observable<any> {
    return this.#apiService.getPromoCodeInfo(code).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap((response) => {
        this.#storage.set('promoCode', response);
      }),
      switchMap((res) => {
        const promoCode: IPromoCode = {
          code: res.code,
          type: res.type,
          rewarded: false,
          reward: res.amount,
        };
        return this.#store.selectSnapshot(UserState.user)
          ? dispatch(new ActivateRefCode(res.code))
          : dispatch(new OpenPromoModal(promoCode));
      }),
      catchError(({ error, status }: HttpErrorResponse) => {
        if (status !== 404) {
          this.#storage.set('unknownPromoCode', code);
        }
        this.#notificationsService.addErrorNotification(error.message);
        return of(error);
      }),
    );
  }

  @Action(OpenPromoModal)
  openPromoModal({ dispatch }: StateContext<ReferralsStateModel>, { code }: OpenPromoModal): void {
    if (!this.#store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.REFERRAL)) {
      dispatch(new OpenModal(ModalNames.REFERRAL, code));
    }
  }

  @Action(InitSuccess)
  initSuccess({ dispatch }: StateContext<ReferralsStateModel>): void {
    const affise = this.#storage.get('affise');
    const refCode = this.#getRefCodeFromStorage();
    const promoCode = this.#getPromoCodeFromStorage();
    const computedCode = promoCode || refCode;

    if (computedCode) {
      dispatch(new ActivateRefCode(computedCode));
    }
    if (affise) {
      dispatch(new AffiseEvent(affise));
    }
  }

  @Action(Logout)
  logout({ dispatch }: StateContext<ReferralsStateModel>): Observable<any> {
    return dispatch([new StateReset(ReferralsState)]);
  }

  #getRefCodeFromStorage(): string | undefined {
    const refCode = this.#storage.get('referralCode');
    const unknownRefCode = this.#storage.get('unknownRefCode');
    return refCode ? refCode.code : unknownRefCode;
  }

  #getPromoCodeFromStorage(): string | undefined {
    const promoCode = this.#storage.get('promoCode');
    const unknownPromoCode = this.#storage.get('unknownPromoCode');
    return promoCode ? promoCode.code : unknownPromoCode;
  }

  #removeCodesFromStorage(): void {
    this.#storage.removeMultiple(['referralCode', 'unknownRefCode', 'promoCode', 'unknownPromoCode']);
  }

  /**
   * Переброс пользователя с /#r/123 на ?ref=123
   */
  #redirectFromLegacyPromo(url: string): void {
    const [page, data] = url.split('#r/');
    const [code, query] = data.split('?');
    this.#router.navigate([page], { queryParams: { ref: code + (query ? '&' + query : '') } });
  }

  #onError(e: HttpErrorResponse, msg?: string, system = true): Observable<any> {
    const { error } = e;
    this.#notificationsService.addErrorNotification(msg || error.message || error.error, {
      icon: 'warning',
      system: error.system || system,
    });
    return of();
  }
}
